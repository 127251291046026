import React, { useEffect, useState } from 'react';
import ModalImage from 'react-modal-image';
import Button from '@mui/material/Button';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import Form from '../../style-components/Form/Form.jsx';
import Select from '../../style-components/Form/Select.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import { getAllImages } from '../../models/images';
import {
  postQuestion,
  patchQuestion,
  postQuestionToEvaluation,
  patchQuestionEvaluation,
} from '../../models/questions';
import { useGetEvaluation } from '../../models/evaluations';
import { getLearnunits } from '../../models/learnunits';
import { questionGenerateExplain, questionGenerateTips } from '../../models/MediBot';
import { getCurrentUser, getUsersWithPagination } from '../../models/user.js';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
  },
  column: {
    flex: 1, // Each column will take up an equal share of the available width
    minWidth: '320px', // Set a minimum width for each column (adjust as needed)
  },
};

const QuestionForm = ({ unit_id, question, method, eval_id, setOpen }) => {
  let learnunit_id = unit_id !== '0' ? unit_id : null;

  const [learningUnits, setLearningUnits] = useState([]);
  const [images, setImages] = useState([]);
  const [evaluation] = useGetEvaluation(eval_id);
  const [imagesDict, setImagesDict] = useState({});
  const [formState, setFormState] = useState({
    content: question?.content ?? '',
    question_type: question?.question_type ?? 'ALTERNATIVAS',
    difficulty: question?.difficulty ?? 'FACIL',
    domain: question?.domain ?? 'IDENTIFICAR',
    total_points: question?.total_points ?? '100',
    correct_answer: question
      ? question.question_type === 'DESARROLLO' && question.possible_answers
        ? question.possible_answers.join(';')
        : question.correct_answer || 0
      : 0,
    example_correct_answer: question?.example_correct_answer ?? '',
    explanation_question_answers: question?.explanation_question_answers ?? '',
    possible_answers: question?.possible_answers ?? ['', '', '', ''],
    time_to_answer: question?.time_to_answer ?? '',
    tips: question?.tips ? question.tips.join(';') : '',
    image_id: question?.image_id ?? '',
    learnunit_id: question?.learnunit_id ?? learnunit_id,
    eval_id: question?.eval_id ?? eval_id,
    table: question?.table ?? '',
    order: question?.order ?? '',
    share: true,
  });
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [role, setRole] = useState('TEACHER');
  const [ownerId, setOwnerId] = useState(question?.owner_id ?? '');

  useEffect(() => {
    getAllImages().then(data => {
      setImages(data);
      const dict = {};
      data.forEach(image => {
        dict[image.id] = image;
      });
      setImagesDict(dict);
    });
    getLearnunits().then(data => {
      setLearningUnits(data.data);
    });
    getCurrentUser().then(currentUser => {
      setRole(currentUser.role);
      if (currentUser.role === 'ADMIN') {
        getUsersWithPagination(1, 9999, 'TEACHER').then(res =>
          setTeachers(
            res.users.map(user => ({
              value: user.id,
              label: `${user.firstnames} ${user.lastnames}`,
            }))
          )
        );
      } else if (currentUser.role === 'TEACHER') {
        setOwnerId(currentUser.id);
      }
    });
  }, []);

  const handleSubmit = async ai => {
    const toPost = {
      ...formState,
      tips: formState.tips.split(';'),
    };
    if (toPost.question_type === 'DESARROLLO') {
      toPost.possible_answers = toPost.correct_answer.split(';');
      toPost.correct_answer = null;
      if (!toPost.possible_answers) {
        alert(`Debes colocar una respuesta correcta.`);
        return;
      }
    }
    if (toPost.image_id === '') {
      toPost.image_id = null;
    }
    if (toPost.time_to_answer && toPost.time_to_answer < 1) {
      alert('El tiempo para responder debe ser mayor a 0.');
      return;
    } else if (toPost.time_to_answer === '') {
      toPost.time_to_answer = null;
    }

    if (
      eval_id &&
      toPost.table !== '' &&
      (toPost.table < 1 || toPost.table > evaluation.tables_per_evaluation)
    ) {
      alert(
        `La mesa asignada a la pregunta no es válida. Revisa que sea un número entero positivo.`
      );
      return;
    } else if (toPost.table === '') {
      toPost.table = null;
    }
    if (eval_id && toPost.order !== '' && toPost.order < 1) {
      alert(
        `El orden asignado a la pregunta no es válida. Revisa que sea un número entero positivo.`
      );
      return;
    } else if (toPost.order === '') {
      toPost.order = null;
    }
    toPost.owner_id = ownerId;

    let response2 = true;
    setLoading(true);
    if (method === 'POST') {
      const response = await postQuestion(toPost);
      if (response.status !== 201 && response.status !== 200) {
        setLoading(false);
        alert('Error al añadir la pregunta. Intente de nuevo.');
        return;
      }
      if (eval_id) {
        response2 = await postQuestionToEvaluation({
          eval_id,
          question_id: response.data.id,
          table: toPost.table,
          order: toPost.order,
        });
      }
      if (response2 === true || response2.status === 201 || response2.status === 200) {
        setLoading(false);
        alert('Pregunta añadida con éxito.');
        setOpen(false);
        window.location.reload();
      } else {
        setLoading(false);
        alert('Error al añadir la pregunta. Intente de nuevo.');
      }
    } else if (method === 'PATCH') {
      const response = await patchQuestion(question.id, toPost);
      if (response.status !== 201 && response.status !== 200) {
        setLoading(false);
        alert('Error al editar la pregunta. Intente de nuevo.');
        return;
      }
      if (eval_id) {
        response2 = await patchQuestionEvaluation({
          eval_id,
          question_id: question.id,
          table: toPost.table,
          order: toPost.order,
        });
      }
      if (response2 !== true && response2.status !== 201 && response2.status !== 200) {
        setLoading(false);
        alert('Error al añadir pregunta. Intente de nuevo.');
        return;
      }
      setLoading(false);
      if (ai === 'explain') {
        questionGenerateExplain(question.id).then(res =>
          setFormState({
            ...formState,
            explanation_question_answers: res.explanation_question_answers,
          })
        );
        // Using setTimeout to wait for 1 second
        setTimeout(() => {
          alert(
            'Se está generando la explicación respuestas de la pregunta. Esto puede tomar un tiempo (entre 20 segundos y 3 minutos). Pasados 30 segundos debes refrescar la página hasta ver los cambios.'
          );
        }, 1000);
      } else if (ai === 'tips') {
        questionGenerateTips(question.id).then(res =>
          setFormState({
            ...formState,
            tips: res.tips.join(';'),
          })
        );
        // Using setTimeout to wait for 1 second
        setTimeout(() => {
          alert(
            'Se está generando la explicación respuestas de la pregunta. Esto puede tomar un tiempo (entre 20 segundos y 3 minutos). Pasados 30 segundos debes refrescar la página hasta ver los cambios.'
          );
        }, 1000);
      } else {
        alert('Pregunta editada con éxito.');
        setOpen(false);
        window.location.reload();
      }
    }
  };

  const handleChange = e => {
    const { name, value, alternative } = e.target;
    if (name === 'possible_answers') {
      formState.possible_answers[alternative] = value;
      setFormState({
        ...formState,
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  return (
    <Form
      title={`${method === 'POST' ? 'Añadir' : 'Editar'} pregunta`}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      <div style={styles.container}>
        <div style={styles.column}>
          <Select
            name='question_type'
            label='Tipo de pregunta'
            value={formState.question_type}
            onChange={handleChange}
            required
            options={[
              { value: 'ALTERNATIVAS', label: 'Alternativas' },
              { value: 'V/F', label: 'V/F' },
              { value: 'DESARROLLO', label: 'Desarrollo' },
            ]}
          />
          <Select
            name='learnunit_id'
            label='Unidad de Aprendizaje'
            value={learningUnits?.length > 0 ? formState.learnunit_id : ''}
            onChange={handleChange}
            required
            options={learningUnits?.map(learnunit => ({
              value: learnunit.id,
              label: learnunit.name,
            }))}
            disabled={method === 'PATCH' || learnunit_id ? true : false}
          />
          <TextField
            label='Pregunta'
            name='content'
            value={formState.content}
            onChange={handleChange}
            required
            multiline
            minRows={2}
          />
          {formState.question_type === 'ALTERNATIVAS' ? (
            <Select
              name='correct_answer'
              label='Respuesta correcta'
              value={formState.correct_answer}
              onChange={handleChange}
              required
              options={[
                { value: '0', label: 'Alternativa A' },
                { value: '1', label: 'Alternativa B' },
                { value: '2', label: 'Alternativa C' },
                { value: '3', label: 'Alternativa D' },
              ]}
            />
          ) : null}
          {formState.question_type === 'V/F' ? (
            <Select
              name='correct_answer'
              label='Respuesta correcta'
              value={formState.correct_answer}
              onChange={handleChange}
              required
              options={[
                { value: '0', label: 'Falso' },
                { value: '1', label: 'Verdadero' },
              ]}
            />
          ) : null}
          {formState.question_type === 'DESARROLLO' ? (
            <TextField
              name='correct_answer'
              label='Respuesta correcta'
              value={formState.correct_answer}
              onChange={handleChange}
              required
              helperText='Coloca palabras claves separadas por ;'
            />
          ) : null}
          {formState.question_type === 'DESARROLLO' ? (
            <TextField
              name='example_correct_answer'
              label='Ejemplo de respuesta correcta'
              value={formState.example_correct_answer}
              onChange={handleChange}
              required
              multiline
              minRows={2}
            />
          ) : null}
          {formState.question_type === 'ALTERNATIVAS' ? (
            <>
              <TextField
                name='possible_answers'
                label='Alternativa A'
                value={formState.possible_answers[0]}
                onChange={e => {
                  e.target.alternative = 0;
                  handleChange(e);
                }}
                required
                multiline
              />
              <TextField
                name='possible_answers'
                label='Alternativa B'
                value={formState.possible_answers[1]}
                onChange={e => {
                  e.target.alternative = 1;
                  handleChange(e);
                }}
                required
                multiline
              />
              <TextField
                name='possible_answers'
                label='Alternativa C'
                value={formState.possible_answers[2]}
                onChange={e => {
                  e.target.alternative = 2;
                  handleChange(e);
                }}
                required
                multiline
              />
              <TextField
                name='possible_answers'
                label='Alternativa D'
                value={formState.possible_answers[3]}
                onChange={e => {
                  e.target.alternative = 3;
                  handleChange(e);
                }}
                required
                multiline
              />
            </>
          ) : null}
        </div>

        <div style={styles.column}>
          {eval_id ? (
            <>
              <TextField
                label='Mesa'
                name='table'
                type='number'
                value={formState.table}
                onChange={handleChange}
              />
              <TextField
                label='Orden'
                name='order'
                type='number'
                value={formState.order}
                onChange={handleChange}
              />
            </>
          ) : null}
          <TextField
            name='time_to_answer'
            label='Tiempo para responder (segundos)'
            value={formState.time_to_answer}
            onChange={handleChange}
            type='number'
          />

          <TextField
            name='explanation_question_answers'
            label='Explicación pregunta respuestas'
            value={formState.explanation_question_answers}
            onChange={handleChange}
            multiline
            minRows={2}
            helperText='Explicación de porqué la respuesta correcta es correcta y las incorrectas son incorrectas.'
          />
          {method === 'PATCH' ? (
            <Button
              sx={{ ml: 2, mb: 2 }}
              variant='contained'
              onClick={() => handleSubmit('explain')}
              color='success'>
              Generar explicación con IA
            </Button>
          ) : null}
          <TextField
            name='tips'
            label='Pistas'
            value={formState.tips}
            onChange={handleChange}
            helperText='Coloca pistas separadas por ;'
            multiline
          />
          {method === 'PATCH' ? (
            <Button
              sx={{ ml: 2, mb: 2 }}
              variant='contained'
              onClick={() => handleSubmit('tips')}
              color='success'>
              Generar pistas con IA
            </Button>
          ) : null}
        </div>

        <div style={styles.column}>
          {role === 'ADMIN' ? (
            <Select
              name='owner_id'
              label='Profesor'
              value={ownerId}
              onChange={e => setOwnerId(e.target.value)}
              options={teachers}
              sx={{ minWidth: '200px', mt: '2rem' }}
              required
            />
          ) : null}
          <Button
            sx={{ ml: 2 }}
            startIcon={<OpenInNewOutlinedIcon />}
            onClick={() => window.open('/images', '_blank')}>
            Ver imágenes
          </Button>
          <Select
            label='Imagen'
            name='image_id'
            value={images.length > 0 ? formState.image_id : ''}
            onChange={handleChange}
            options={[
              { value: '', label: 'Sin imagen asociada' },
              ...images.map(image => ({ value: image.id, label: image.details })),
            ]}
          />
          {formState.image_id !== '' ? (
            <ModalImage
              small={imagesDict[formState.image_id]?.url}
              large={imagesDict[formState.image_id]?.url}
              alt={imagesDict[formState.image_id]?.details}
              hideZoom
              hideDownload
            />
          ) : null}
        </div>
      </div>
    </Form>
  );
};

export default QuestionForm;
